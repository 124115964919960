import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import { isEmpty } from "lodash";
import moment from "moment";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useRef } from 'react';
import * as yup from 'yup';
import { siteSettings } from '../content/siteSettings';

import {
    useGetAirports,
    useGetBookingFormSetting,
    useAddBookingRequest,
    useGetSiteSetting,
    useGetTest
} from "../queries/hooks/index";
import localStorageService from "../helpers/localStorageService";
import { formatPhoneNumber } from "../helpers/utils";

import "../styles/base.scss";

function BookingForm(props) {
    const navigate = useNavigate();
    const { destinationData } = props;
    const multiBlock = useRef(null);


    // const disabledDate = (current) => {
    //   // Can not select days before today and today
    //   return current && current < moment().endOf("day");
    // };

    const initialBookingState = {
        trip_type: 2,
        from_airport: {
            id: props?.destinationData?.from_airport?.id || -1,
            name: props?.destinationData?.from_airport?.name || "",
            iata_code: props?.destinationData?.from_airport?.iata_code || "",
        },
        to_airport: {
            id: props?.destinationData?.to_airport?.id || -1,
            name: props?.destinationData?.to_airport?.name || "",
            iata_code: props?.destinationData?.to_airport?.iata_code || "",
        },
        departure_date: "",
        return_date: "",
        // class: { id: 3, name: "Business" },
        class: { id: -1, name: "Select Flight Class" },
        travellers: {
            seniors: 0,
            adult: 1,
            child: 0,
            infant_in_seat: 0,
            infant_in_lap: 0,
        },
        totalTravellers: 1,
        trip_cities: [
            {
                from_airport: {
                    id: props?.destinationData?.from_airport?.id || -1,
                    name: props?.destinationData?.from_airport?.name || "",
                    iata_code: props?.destinationData?.from_airport?.iata_code || "",
                },
                to_airport: {
                    id: props?.destinationData?.to_airport?.id || -1,
                    name: props?.destinationData?.to_airport?.name || "",
                    iata_code: props?.destinationData?.to_airport?.iata_code || "",
                },
                departure_date: "",
            },
            {
                from_airport: {
                    id: props?.destinationData?.from_airport?.id || -1,
                    name: props?.destinationData?.from_airport?.name || "",
                    iata_code: props?.destinationData?.from_airport?.iata_code || "",
                },
                to_airport: {
                    id: props?.destinationData?.to_airport?.id || -1,
                    name: props?.destinationData?.to_airport?.name || "",
                    iata_code: props?.destinationData?.to_airport?.iata_code || "",
                },
                departure_date: "",
            },
        ],
        first_name: "",
        last_name: "",
        name: "",
        email: "",
        phone: "",
        accommodation: true,
        minDate: moment().format("YYYY-MM-DD"),
        maxDate: moment().add(1, 'year').format("YYYY-MM-DD"),
        limitTravelers: 6,
    };

    const [bookingFormValues, setBookingFormValues] = useState(initialBookingState);

    const [nextButtonDisabled, setNextButtonDisabled] = useState(false);
    const [submitButtonDisabled, setSubmitButtonDisabled] = useState(false);
    const [multiCityNextButtonDisabled, setMultiCityNextButtonDisabled] = useState(false);
    const [isErrorTravellers, setIsErrorTravellers] = useState(false);
    const [isSameAirports, setIsSameAirports] = useState(false);
    const [isOpenTravellers, setIsOpenTravellers] = useState(false);

    useEffect(() => {
        setBookingFormValues(initialBookingState);
        window.scrollTo(0, 0);
    }, [destinationData]);

    useEffect(() => {
        checkNextButtonDisabled();
        checkSubmitDisabled();
        checkMultiCityNextButtonDisabled();
    }, [bookingFormValues]);

    useEffect(() => {
        window.addEventListener('click', closeOpenTravellers.bind(isOpenTravellers));
    }, [isOpenTravellers, bookingFormValues]);


    const [showSuccessModal, setShowSuccessModal] = useState(false);

    // implementation of auto-suggestion
    const getAirPorts = useGetAirports();
    const [active, setActive] = useState(0);
    const [filtered, setFiltered] = useState([]);
    const [isShowFrom, setIsShowFrom] = useState(false);
    const [isShowTo, setIsShowTo] = useState(false);
    const [from_airport, setFrom_airport] = useState("");
    const [to_airport, setTo_airport] = useState("");
    const [activeSearchBoxIndex, setActiveSearchBoxIndex] = useState(-1);

    const { data: formData, isFetching: fetching } = useGetBookingFormSetting();
    const { data: socialMediaLinks, isFetching: Loading } = useGetSiteSetting();
    const addBooking = useAddBookingRequest();
    // const test = useGetTest();

    const travellersBtnHandler = (event) => {
        event.stopPropagation();

        if (isOpenTravellers === false) {
            setIsOpenTravellers(true);
        } else if (isOpenTravellers === true) {
            setIsOpenTravellers(false);
            if (bookingFormValues.class.id === -1) setIsErrorTravellers(true);
            else setIsErrorTravellers(false);
        }
    }

    const closeOpenTravellers = (e) => {

        if (isOpenTravellers === true && bookingFormValues.class.id === -1) {
            setIsOpenTravellers(false);
            setIsErrorTravellers(true);
        }
        if (bookingFormValues.class.id !== -1) setIsErrorTravellers(false);

    }

    const formik = useFormik({
        initialValues: {
            from_airport: "",
            to_airport: "",
            trip_type: 1,
            departure_date: "",
            return_date: "",
            travellers: "",
            class: "",
        },
        onSubmit: (values) => {
            localStorageService.set("firstFormValues", values);
        },
    });
    const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

    // const yupValidationSchema = yup.object().shape({
    //   first_name: yup.string().required("required!"),
    //   last_name: yup.string().required("required!"),
    //   email: yup.string().email().required("required!"),
    //   phone: yup.string().required("required!"),
    // });

    const finalSubmission = useFormik({
        initialValues: {
            from_airport: bookingFormValues?.from_airport?.id || "",
            to_airport: bookingFormValues?.to_airport?.id || "",
            trip_type: bookingFormValues?.trip_type || "",
            departure_date: bookingFormValues?.departure_date || "",
            return_date: bookingFormValues?.return_date || "",
            travellers: bookingFormValues?.travellers || "",
            class: bookingFormValues?.class?.id || "",
            name: "",
            email: "",
            phone: "",
            accommodation: true,
        },
        // validationSchema: yupValidationSchema,
        onSubmit: () => {
            let urlParams = sessionStorage.getItem('urlParams');
            const payloadRequest = {
                trip_type: bookingFormValues.trip_type,
                class: bookingFormValues.class?.id,
                travellers: JSON.stringify(bookingFormValues.travellers),
                name: bookingFormValues.name ? bookingFormValues.name : `${bookingFormValues.first_name} ${bookingFormValues.last_name}`,
                email: bookingFormValues.email,
                phone: bookingFormValues.phone,
                company: 'Flight Insiders'
            };

            if (bookingFormValues.trip_type === 1 || bookingFormValues.trip_type === 2) {
                payloadRequest.from_airport = bookingFormValues.from_airport?.id;
                payloadRequest.to_airport = bookingFormValues.to_airport?.id;
                payloadRequest.departure_date = bookingFormValues.departure_date;
                payloadRequest.return_date = bookingFormValues.return_date;
            } else if (bookingFormValues.trip_type === 3) {
                const tripCities = bookingFormValues.trip_cities.map((city) => {
                    return {
                        from_airport: city.from_airport.id,
                        to_airport: city.to_airport.id,
                        departure_date: city.departure_date,
                        return_date: city.departure_date,
                    };
                });
                payloadRequest.trip_cities = JSON.stringify(tripCities);
            }

            let params = new URLSearchParams(document.location.search);

            let paramsObject = {};
            for (let [key, value] of params.entries()) {
                paramsObject[key] = value;
            }

            if (paramsObject == {} && document.referrer && document.referrer.includes('https://www.google.com/')) {
                paramsObject.utm_source = 'Google Search';
            }
          
            payloadRequest.url_params = JSON.stringify(paramsObject);

            navigate("/thankyou");

            setShowSuccessModal(true);
            addBooking.mutate(payloadRequest);
            setBookingFormValues(initialBookingState);
        },
    });

    const renderErrorMessage = (field) => {
        let error = "";
        const value = bookingFormValues[field];
        if (!value) {
            error = "Required";
        } else if (field === "email" && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(value)) {
            error = "Invalid email address";
        } else if ((field === "first_name" || field === "last_name") && !/^(?=.{3,50}$)[a-z]+(?:['_.\s][a-z]+)*$/i.test(value)) {
            error = "Invalid name";
        } else if ((field === "phone") && !/[0-9]/i.test(value)) {
            error = "Invalid format";
        }

        return error !== "" ? <div className="text-error">{error}</div> : "";
    };

    const checkSubmitDisabled = () => {
        if (renderErrorMessage('first_name') !== '') {
            setSubmitButtonDisabled(true);
        } else if (renderErrorMessage('last_name') !== '') {
            setSubmitButtonDisabled(true);
        } else if (renderErrorMessage('email') !== '') {
            setSubmitButtonDisabled(true);
        } else if (renderErrorMessage('phone') !== '') {
            setSubmitButtonDisabled(true);
        } else {
            setSubmitButtonDisabled(false);
        }
    }

    function toggleDestinations() {
        const switchDestinationValue = bookingFormValues.from_airport;
        bookingFormValues.from_airport = bookingFormValues.to_airport;
        bookingFormValues.to_airport = switchDestinationValue;
        setBookingFormValues({ ...bookingFormValues });
    }

    function toggleDestinationsMultiCity(flightIndex) {
        const switchDestinationValue = bookingFormValues.trip_cities[flightIndex].from_airport;
        bookingFormValues.trip_cities[flightIndex].from_airport = bookingFormValues.trip_cities[flightIndex].to_airport;
        bookingFormValues.trip_cities[flightIndex].to_airport = switchDestinationValue;
        setBookingFormValues({ ...bookingFormValues });
    }


    const handleBookingFormChange = (e, prop, mode = "") => {
        setBookingFormValues({
            ...bookingFormValues,
            ...prop,
        });
        if (mode === 'mode') {
            setIsShowFrom(false);
            setIsShowTo(false);
        }
    };

    // console.log(bookingFormValues)

    const checkNextButtonDisabled = () => {
        if (bookingFormValues?.from_airport?.id === -1) {
            setNextButtonDisabled(true);
        } else if (bookingFormValues?.to_airport?.id === -1) {
            setNextButtonDisabled(true);
        } else if (bookingFormValues.from_airport.id === bookingFormValues.to_airport.id) {
            setNextButtonDisabled(true);
        } else if (isEmpty(bookingFormValues?.departure_date)) {
            setNextButtonDisabled(true);
        } else if (bookingFormValues?.trip_type === 2 && isEmpty(bookingFormValues?.return_date)) {
            setNextButtonDisabled(true);
        } else if (
            bookingFormValues?.travellers.seniors <= 0 &&
            bookingFormValues?.travellers.adult <= 0 &&
            bookingFormValues?.travellers.child <= 0 &&
            bookingFormValues?.travellers.infant_in_seat <= 0 &&
            bookingFormValues?.travellers.infant_in_lap <= 0
        ) {
            setNextButtonDisabled(true);
        } else if (bookingFormValues?.class.id === -1) {
            setNextButtonDisabled(true);
        } else if (bookingFormValues.totalTravellers > bookingFormValues.limitTravelers) {
            setNextButtonDisabled(true);
        } else {
            setNextButtonDisabled(false);
        }

        if (bookingFormValues?.class.id != -1) setIsErrorTravellers(false);
    };

    const checkMultiCityNextButtonDisabled = () => {
        if (bookingFormValues.trip_cities.some(trip => trip.from_airport.id === -1 || trip.to_airport.id === -1)) {
            setMultiCityNextButtonDisabled(true);
        } else if (bookingFormValues.trip_cities.some(trip => trip.from_airport.id === trip.to_airport.id)) {
            setMultiCityNextButtonDisabled(true);
        } else if (bookingFormValues.trip_cities.some(trip => !trip.departure_date)) {
            setMultiCityNextButtonDisabled(true);
        } else if (
            bookingFormValues?.travellers.seniors <= 0 &&
            bookingFormValues?.travellers.adult <= 0 &&
            bookingFormValues?.travellers.child <= 0 &&
            bookingFormValues?.travellers.infant_in_seat <= 0 &&
            bookingFormValues?.travellers.infant_in_lap <= 0
        ) {
            setMultiCityNextButtonDisabled(true);
        } else if (bookingFormValues?.class.id === -1) {
            setMultiCityNextButtonDisabled(true);
        } else if (bookingFormValues.totalTravellers > bookingFormValues.limitTravelers) {
            setMultiCityNextButtonDisabled(true);
        } else {
            setMultiCityNextButtonDisabled(false);
        }

        if (bookingFormValues?.class.id !== -1) setIsErrorTravellers(false);
    };

    const handleBookingFormNext = () => {
        localStorageService.set("bookingFormValues", bookingFormValues);
    };

    const FromAirportChangeHandler = async (e, flightIndex = -1) => {
        e.preventDefault();
        const search_str = e.currentTarget.value;
        // setFrom_airport(e.currentTarget.value);
        if (flightIndex === -1) {
            setBookingFormValues({
                ...bookingFormValues,
                from_airport: { id: -1, name: e.currentTarget.value },
            });
        } else {
            if (flightIndex !== -1 && activeSearchBoxIndex !== flightIndex) {
                setActiveSearchBoxIndex(flightIndex);
            }
            bookingFormValues.trip_cities[flightIndex].from_airport = {
                id: -1,
                name: e.currentTarget.value,
            };
            setBookingFormValues({
                ...bookingFormValues,
            });
        }

        if (search_str?.length > 2) {
            const data = new FormData();
            data.append("search_str", search_str);
            const reponse = await getAirPorts.mutateAsync(search_str);

            setActive(0);
            setFiltered(reponse?.airports);
            setIsShowFrom(true);
        } else {
            // setActive(0);
            // setFiltered([]);
            setIsShowFrom(false);
        }
        // else{
        //   alert('in else');
        //   setBookingFormValues({
        //     ...bookingFormValues,
        //     from_airport: { id: -1},
        //   });
        // }
    };

    const ToAirPortChangeHandler = async (e, flightIndex = -1) => {
        e.preventDefault();
        const search_str = e.currentTarget.value;

        if (flightIndex === -1) {
            setBookingFormValues({
                ...bookingFormValues,
                to_airport: { id: -1, name: e.currentTarget.value },
            });
        } else {
            if (flightIndex !== -1 && activeSearchBoxIndex !== flightIndex) {
                setActiveSearchBoxIndex(flightIndex);
            }
            bookingFormValues.trip_cities[flightIndex].to_airport = {
                id: -1,
                name: e.currentTarget.value,
            };
            setBookingFormValues({
                ...bookingFormValues,
            });
        }

        if (search_str?.length > 2) {
            const data = new FormData();
            data.append("search_str", search_str);
            const reponse = await getAirPorts.mutateAsync(search_str);

            setActive(0);
            setFiltered(reponse?.airports);
            setIsShowTo(true);
        } else {
            // setActive(0);
            // setFiltered([]);
            setIsShowTo(false);
        }
    };


    const FromAirPortOnClick = (e, id, name, iata_code, flightIndex = -1) => {
        setActive(0);
        setFiltered([]);
        setIsShowFrom(false);
        setFrom_airport(name);
        formik.setFieldValue("from_airport", id);
        localStorageService.set("fromAirPort", name);
        if (flightIndex === -1) {
            setBookingFormValues({
                ...bookingFormValues,
                from_airport: { id: id, name: name, iata_code: iata_code },
            });
        } else {
            bookingFormValues.trip_cities[flightIndex].from_airport = {
                id: id,
                name: name,
                iata_code: iata_code,
            };
            setBookingFormValues({
                ...bookingFormValues,
            });
        }

        localStorageService.set("fromAirportData", {
            id: id,
            name: name,
            iata_code: iata_code,
        });
    };

    const ToAirPortOnClick = (e, id, name, iata_code, flightIndex = -1) => {
        setActive(0);
        setFiltered([]);
        setIsShowTo(false);
        setTo_airport(name);
        formik.setFieldValue("to_airport", id);
        localStorageService.set("toAirPort", name);

        if (flightIndex === -1) {
            setBookingFormValues({
                ...bookingFormValues,
                to_airport: { id: id, name: name, iata_code: iata_code },
            });
        } else {
            bookingFormValues.trip_cities[flightIndex].to_airport = {
                id: id,
                name: name,
                iata_code: iata_code,
            };
            setBookingFormValues({
                ...bookingFormValues,
            });
        }

        localStorageService.set("toAirportData", {
            id: id,
            name: name,
            iata_code: iata_code,
        });
    };

    const FromAirPortOnKeyDown = (e) => {
        if (e.keyCode === 13) {
            // enter key
            setActive(0);
            setIsShowFrom(false);
            setFrom_airport(filtered[active]);
        } else if (e.keyCode === 38) {
            // up arrow
            return active === 0 ? null : setActive(active - 1);
        } else if (e.keyCode === 40) {
            // down arrow
            return active - 1 === filtered?.length ? null : setActive(active + 1);
        }
    };

    const ToAirPortOnKeyDown = (e) => {
        if (e.keyCode === 13) {
            // enter key
            setActive(0);
            setIsShowTo(false);
            setTo_airport(filtered[active]);
        } else if (e.keyCode === 38) {
            // up arrow
            return active === 0 ? null : setActive(active - 1);
        } else if (e.keyCode === 40) {
            // down arrow
            return active - 1 === filtered?.length ? null : setActive(active + 1);
        }
    };

    const FromAirPortRenderAutocomplete = (flightIndex = -1) => {
        if (isShowFrom) {
            if (filtered?.length) {
                return (
                    <ul className="autocomplete_from" style={{ minHeight: "50px", maxHeight: "150px" }}>
                        {filtered?.map((airport, index) => {
                            let className;
                            if (index === active) {
                                className = "active";
                            }
                            return (
                                <li
                                    className={className}
                                    key={airport.id}
                                    onClick={(e) => {
                                        FromAirPortOnClick(e, airport.id, airport.name, airport.iata_code, flightIndex);
                                        // var airpot_name = airport.name;
                                        // setBookingFormValues.airpot_name = airpot_name;
                                        // localStorageService.set("from_airpot_name", airpot_name);
                                    }}
                                >
                                    <i className="fas fa-plane"></i>
                                    {`${airport.name} (${airport.iata_code})`}
                                    <p>{airport.city}</p>
                                </li>
                            );
                        })}
                    </ul>
                );
            } else {
                return (
                    <div
                        className="no-autocomplete"
                        style={{
                            position: "absolute",
                            top: "39px",
                            color: "red",
                            visibility: bookingFormValues.from_airport.name === "" ? "hidden" : "",
                        }}
                    >
                        <em>Not found</em>
                    </div>
                );
            }
        }
        return <></>;
    };

    const ToAirPortRenderAutocomplete = (flightIndex = -1) => {
        if (isShowTo) {
            if (filtered?.length) {
                return (
                    <ul className={flightIndex === -1 ? "autocomplete_to" : "autocomplete_to_multicity"} style={{ minHeight: "50px", maxHeight: "150px" }} >
                        {filtered?.map((suggestion, index) => {
                            let className;
                            if (index === active) {
                                className = "active";
                            }
                            return (
                                <li
                                    className={className}
                                    key={suggestion.id}
                                    onClick={(e) => {
                                        ToAirPortOnClick(e, suggestion.id, suggestion.name, suggestion.iata_code, flightIndex);
                                        var airpot_name = suggestion.name;
                                        localStorageService.set("to_airpot_name", airpot_name);
                                    }}
                                >
                                    <i className="fas fa-plane"></i>
                                    {suggestion.name}
                                    <p>{suggestion.city}</p>
                                </li>
                            );
                        })}
                    </ul>
                );
            } else {
                return (
                    <div
                        className="no-autocomplete"
                        style={{
                            color: "red",
                            visibility: bookingFormValues.from_airport.name === "" ? "hidden" : "",
                        }}
                    >
                        <em>Not found</em>
                    </div>
                );
            }
        }
        return <></>;
    };

    const handleAddAnotherFlight = () => {
        setBookingFormValues({
            ...bookingFormValues,
            ...bookingFormValues.trip_cities.push({
                from_airport: { iata_code: "", id: -1, name: "" },
                to_airport: { iata_code: "", id: -1, name: "" },
                departure_date: "",
            }),
        });
        // setMultiCityBookingFormValues(newData);
    };

    const handleDeleteFlight = (index) => {
        const idx = bookingFormValues.trip_cities.findIndex((i) => i.id === index);
        // const removedItem = bookingFormValues.trip_cities.splice(idx, 1)[0];
        setBookingFormValues({
            ...bookingFormValues,
            ...bookingFormValues.trip_cities.splice(idx, 1)[0],
        });
    };

    const showTravellers = (value, label, pluralize) => {
        return value > 0 && `${value} ${label}${pluralize ? checkPlural(value) : ""}, `;
    };

    {/*
        {showTravellers(bookingFormValues?.travellers?.seniors, "Senior", true)}

        {showTravellers(bookingFormValues?.travellers?.adult, "Adult", true)}

        {showTravellers(bookingFormValues?.travellers?.child, "Child", true)}

        {showTravellers(bookingFormValues?.travellers?.infant_in_seat, "Infant in seat", false)}

        {showTravellers(bookingFormValues?.travellers?.infant_in_lap, "Infant in lap", false)}
    */}

    const showTravellersCustom = (passengers) => {
        // console.log("Custom Travellers", passengers);
        var custom_array = [];
        var plural = "";
        if (passengers.seniors > 0) {
            if (checkPlural(passengers.seniors)) {
                plural = 's';
            } else {
                plural = '';
            }
            custom_array.push(passengers.seniors + ' Senior' + plural);
        }
        if (passengers.adult > 0) {
            if (checkPlural(passengers.adult)) {
                plural = 's';
            } else {
                plural = '';
            }
            custom_array.push(passengers.adult + ' Adult' + plural);
        }
        if (passengers.child > 0) {
            if (checkPlural(passengers.child)) {
                plural = 's';
            } else {
                plural = '';
            }
            custom_array.push(passengers.child + ' Child' + plural);
        }
        if (passengers.infant_in_seat > 0) {
            custom_array.push(passengers.infant_in_seat + ' Infant in seat');
        }
        if (passengers.infant_in_lap > 0) {
            custom_array.push(passengers.infant_in_seat + ' Infant in lap');
        }
        var converted_custom_array = custom_array.join(', ');
        return converted_custom_array;
    };
    const custom_formatted_date = (date) => {
        const completeDate = new Date(date);
        const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
        var weekdays = new Array(7);
        weekdays[0] = "Sunday";
        weekdays[1] = "Monday";
        weekdays[2] = "Tuesday";
        weekdays[3] = "Wednesday";
        weekdays[4] = "Thursday";
        weekdays[5] = "Friday";
        weekdays[6] = "Saturday";
        var formatted_date = weekdays[completeDate.getDay()] + ", " + months[completeDate.getMonth()] + " " + completeDate.getDate() + "," + completeDate.getFullYear();
        return formatted_date;
    };
    const checkPlural = (number) => {
        return number > 1 ? "s" : "";
    };

    const testHandler = () => {
        test.mutate();
    };

    const decreaseTravels = (event, typeTravel) => {
        const prop = {
            travellers: {
                ...bookingFormValues.travellers,
                [typeTravel]: bookingFormValues.travellers[typeTravel] > 0 ? bookingFormValues.travellers[typeTravel] - 1 : 0,
            },
            totalTravellers: bookingFormValues.travellers[typeTravel] > 0 ? bookingFormValues.totalTravellers - 1 : bookingFormValues.totalTravellers,
        };
        if (bookingFormValues.totalTravellers > 0) {
            handleBookingFormChange(event, prop);
        }
    }
    const increaseTravels = (event, typeTravel) => {
        const prop = {
            travellers: {
                ...bookingFormValues.travellers,
                [typeTravel]: bookingFormValues.travellers[typeTravel] + 1,
            },
            totalTravellers: bookingFormValues.totalTravellers + 1,
        };

        handleBookingFormChange(event, prop);
        /*if (bookingFormValues.totalTravellers >= 0 && bookingFormValues.totalTravellers < bookingFormValues.limitTravelers) {
            handleBookingFormChange(event, prop);
        }*/
    }

    const [startDate, setStartDate] = useState(new Date());

    const detectMaxDepartureDate = (dateString) => {
        if (dateString) return new Date(dateString);
        else return new Date(bookingFormValues.maxDate);
    }

    const detectMinReturnDate = (dateString) => {
        if (dateString) return new Date(dateString);
        else return new Date(bookingFormValues.minDate);
    }

    const formatDate = (date) => {
        let d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();

        if (month.length < 2)
            month = '0' + month;
        if (day.length < 2)
            day = '0' + day;
        return [year, month, day].join('-');
    }


    return (
        <>

            {/*<button onClick={testHandler}>TEST</button>*/}
            <div id="main_banner" className="carousel slide" data-bs-ride="carousel">
                <div className="main_banner_pattern"></div>
                <div className="carousel-inner">
                    <div className="carousel-item active">
                        <img src="images/main_banner.webp" className="d-block w-100 web_main_banner_img" alt="" />
                        <img src="images/mobile_banner.webp" className="d-block w-100 mobile_main_banner_img" alt="" />
                        <div className="container">

                            {!Loading && (
                                <div className="carousel-caption">
                                    <div className="row">
                                        <div className="col-sm-5">

                                            <h4><strong>Let Our Flight Insiders</strong></h4>
                                            <h1><span><strong>Save You</strong> Up To <strong>50% Off</strong></span>
                                                <strong>Business</strong> & <strong>First Class</strong></h1>

                                            <a className="webLearnBannerBtn" type="button">Learn How it Works</a>

                                            <button type="button" className="btn global_btn quote-top-btn"
                                                data-bs-toggle="modal"
                                                data-bs-target="#mobileFlightBookingModal">
                                                Get A Quote
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </div>

                <div className="container mobile_d_none">
                    <div className="banner_form_parent" style={{ zIndex: "10" }}>
                        <div className="row">

                            <div
                                className={`${bookingFormValues.trip_type === 3 ? "col-sm-5" : "col-sm-4"} offset-sm-8 wider_column_form`}>
                                <div className="banner_form">
                                    <div className="row">
                                        <div className="col-sm-12 mb-3">
                                            <div className="form-check form-check-inline">
                                                <input
                                                    className="form-check-input"
                                                    id="trip_type2"
                                                    type="radio"
                                                    name="trip_type"
                                                    checked={bookingFormValues?.trip_type === 2}
                                                    onChange={(e) =>
                                                        handleBookingFormChange(e, {
                                                            trip_type: 2,
                                                            departure_date: "",
                                                            return_date: "",
                                                            from_airport: { id: -1 },
                                                            to_airport: { id: -1 },
                                                            //limitTravelers: 6,
                                                        }, 'mode')
                                                    }
                                                />
                                                <label className="form-check-label label-radio-cursor"
                                                    htmlFor="trip_type2">
                                                    Round Trip
                                                </label>
                                            </div>
                                            <div className="form-check form-check-inline">
                                                <input
                                                    className="form-check-input"
                                                    id="trip_type1"
                                                    type="radio"
                                                    name="trip_type"
                                                    checked={bookingFormValues?.trip_type === 1}
                                                    onChange={(e) =>
                                                        handleBookingFormChange(e, {
                                                            trip_type: 1,
                                                            departure_date: "",
                                                            return_date: "",
                                                            from_airport: { id: -1 },
                                                            to_airport: { id: -1 },
                                                            //limitTravelers: 6,
                                                        }, 'mode')
                                                    }
                                                />
                                                <label className="form-check-label label-radio-cursor"
                                                    htmlFor="trip_type1">
                                                    One Way
                                                </label>
                                            </div>
                                            <div className="form-check form-check-inline">
                                                <input
                                                    className="form-check-input"
                                                    id="trip_type3"
                                                    type="radio"
                                                    name="trip_type"
                                                    checked={bookingFormValues?.trip_type === 3}
                                                    onChange={(e) =>
                                                        handleBookingFormChange(e, {
                                                            trip_type: 3,
                                                            departure_date: "",
                                                            return_date: "",
                                                        }, 'mode')
                                                    }
                                                />
                                                <label className="form-check-label label-radio-cursor"
                                                    htmlFor="trip_type3">
                                                    Multi-City
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                    {bookingFormValues.trip_type === 1 || bookingFormValues.trip_type === 2 ? (
                                        <div className="one_way_form">
                                            <div className="row">
                                                <div className="col-sm-12">
                                                    <div className="directions_box">
                                                        <div className="row">
                                                            <div className="col-sm-2 text-center">
                                                                <div>
                                                                    <img src="images/fi1.svg" alt="Plane" />
                                                                </div>
                                                                <button
                                                                    type="button"
                                                                    className="btn"
                                                                    data-bs-toggle="tooltip"
                                                                    data-bs-placement="left"
                                                                    title="Reverse From and To"
                                                                    onClick={() => toggleDestinations()}
                                                                >
                                                                    <img src="images/switch_location.png" alt="Switch" />
                                                                </button>
                                                                <div>
                                                                    <img src="images/fi2.svg" alt="Plane" />
                                                                </div>
                                                            </div>
                                                            <div
                                                                className="col-sm-10"
                                                                style={{
                                                                    position: "relative",
                                                                }}
                                                            >
                                                                <input
                                                                    name="from_airport"
                                                                    autoComplete="off"
                                                                    type="text"
                                                                    className="form-control mb-5"
                                                                    placeholder="From"
                                                                    required
                                                                    value={bookingFormValues.from_airport.name}
                                                                    // onKeyDown={FromAirPortOnKeyDown}
                                                                    onChange={FromAirportChangeHandler}
                                                                />
                                                                {FromAirPortRenderAutocomplete()}
                                                                <input
                                                                    name="to_airport"
                                                                    autoComplete="off"
                                                                    type="text"
                                                                    required
                                                                    className="form-control"
                                                                    placeholder="To"
                                                                    onChange={ToAirPortChangeHandler}
                                                                    // onKeyDown={ToAirPortOnKeyDown}
                                                                    value={bookingFormValues.to_airport.name}
                                                                />
                                                                {ToAirPortRenderAutocomplete()}
                                                            </div>
                                                        </div>
                                                        {bookingFormValues.from_airport.id !== -1 && bookingFormValues.to_airport.id !== -1 && bookingFormValues.from_airport.id === bookingFormValues.to_airport.id &&
                                                            <div className="row mt-2" style={{ "color": "red" }}>
                                                                <span>Please choose different destinations</span>
                                                            </div>
                                                        }
                                                    </div>
                                                </div>
                                                <div
                                                    className={bookingFormValues.trip_type === 1 ? "col-sm-12" : "col-sm-6"}>
                                                    <div className="form-floating departure-datebox">
                                                        {/*<input
                                                            className="form-control datedropper"
                                                            id="departure"
                                                            name="departure"
                                                            min={bookingFormValues.minDate}
                                                            max={bookingFormValues.return_date || bookingFormValues.maxDate}
                                                            placeholder="Departure"
                                                            type="date"
                                                            value={bookingFormValues.departure_date}
                                                            onChange={(date) =>
                                                                handleBookingFormChange(date, {
                                                                    //departure_date: date.target.value,
                                                                })
                                                            }
                                                        />
                                                        <label htmlFor="departure">Departure Date</label>*/}
                                                        <DatePicker
                                                            popperPlacement="bottom"
                                                            dateFormat="MM/dd/yyyy"
                                                            minDate={new Date(bookingFormValues.minDate)}
                                                            maxDate={detectMaxDepartureDate(bookingFormValues.return_date)}
                                                            className="departure-input 111"
                                                            placeholderText="mm/dd/yyyy"
                                                            selected={!bookingFormValues.departure_date ? null : new Date(bookingFormValues.departure_date)}
                                                            onChange={(date) =>
                                                                handleBookingFormChange(date, {
                                                                    departure_date: formatDate(date),
                                                                })
                                                            }
                                                        />
                                                    </div>
                                                </div>
                                                <div
                                                    className={bookingFormValues.trip_type === 1 ? "d-none" : "col-sm-6"}>
                                                    <div className="form-floating return-datebox">
                                                        {/*<input
                                                            className="form-control datedropper"
                                                            id="return"
                                                            placeholder="Return"
                                                            type="date"
                                                            value={bookingFormValues.return_date}
                                                            onChange={(e) =>
                                                                handleBookingFormChange(e, {
                                                                    return_date: e.target.value,
                                                                })
                                                            }
                                                            // min={bookingFormValues.departure_date ? bookingFormValues.departure_date:bookingFormValues.currentDate}
                                                            min={bookingFormValues.departure_date || bookingFormValues.minDate}
                                                            max={bookingFormValues.maxDate}
                                                        />
                                                        <label htmlFor="return">Return Date</label>*/}
                                                        <DatePicker
                                                            dateFormat="MM/dd/yyyy"
                                                            minDate={detectMinReturnDate(bookingFormValues.departure_date)}
                                                            maxDate={new Date(bookingFormValues.maxDate)}
                                                            className="return-input"
                                                            placeholderText="mm/dd/yyyy"
                                                            selected={!bookingFormValues.return_date ? null : new Date(bookingFormValues.return_date)}
                                                            onChange={(date) =>
                                                                handleBookingFormChange(date, {
                                                                    return_date: formatDate(date),
                                                                })
                                                            }
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-sm-12">
                                                    <div className="dropdown travellers_dropdown">
                                                        <button
                                                            className="btn btn-secondary dropdown-toggle"
                                                            type="button"
                                                            id="travellers_dropdown"
                                                            data-bs-toggle="dropdown"
                                                            data-bs-auto-close="outside"
                                                            aria-expanded="false"
                                                            onClick={travellersBtnHandler}
                                                        >
                                                            <h6>Passengers</h6>
                                                            <h5>
                                                                <span className="pass_span"
                                                                    style={{ color: bookingFormValues.totalTravellers > bookingFormValues.limitTravelers ? "red" : "", }}
                                                                >
                                                                    {bookingFormValues.totalTravellers} Passengers</span>
                                                                <span style={{
                                                                    color: isErrorTravellers ? "red" : "",
                                                                }}
                                                                >
                                                                    {bookingFormValues.class.name}
                                                                </span>
                                                            </h5>
                                                        </button>
                                                        <div className="dropdown-menu"
                                                            aria-labelledby="travellers_dropdown">
                                                            <div className="form-floating">
                                                                <select
                                                                    className="form-select"
                                                                    id="class"
                                                                    value={bookingFormValues.class.id}
                                                                    onChange={(e) => {
                                                                        let index = e.nativeEvent.target.selectedIndex;
                                                                        let label = e.nativeEvent.target[index].text;
                                                                        handleBookingFormChange(e, {
                                                                            class: {
                                                                                id: e.currentTarget.value,
                                                                                name: label,
                                                                            },
                                                                        });
                                                                    }}
                                                                >
                                                                    {/* <option value="">Select Flight Class</option> */}
                                                                    <option value="-1" disabled>Select Flight
                                                                        Class
                                                                    </option>
                                                                    {formData?.flight_classes?.map((flight) => (
                                                                        <option value={flight.id} key={flight?.id}>
                                                                            {flight?.name}
                                                                        </option>
                                                                    ))}
                                                                </select>
                                                                <label htmlFor="classSelect">Cabin Class</label>
                                                            </div>
                                                            <div className="quantity_selection_box">
                                                                <div className="row align-items-center">
                                                                    <div className="col-sm-6">
                                                                        <h4>
                                                                            Adult <span>12-64</span>
                                                                        </h4>
                                                                    </div>
                                                                    <div className="col-sm-6 text-end">
                                                                        <div className="quantity_counter">
                                                                            <span
                                                                                className="down"
                                                                                onClick={(e) => decreaseTravels(e, 'adult')}
                                                                            >
                                                                                <i className="fa-solid fa-minus"></i>
                                                                            </span>
                                                                            <input type="text"
                                                                                readOnly
                                                                                value={bookingFormValues.travellers.adult} />
                                                                            <span
                                                                                className="up"
                                                                                onClick={(e) => increaseTravels(e, 'adult')}
                                                                            >
                                                                                <i className="fa-solid fa-plus"></i>
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="row align-items-center">
                                                                    <div className="col-sm-6">
                                                                        <h4>
                                                                            Seniors <span>65+</span>
                                                                        </h4>
                                                                    </div>
                                                                    <div className="col-sm-6 text-end">
                                                                        <div className="quantity_counter">
                                                                            <span
                                                                                className="down"
                                                                                onClick={(e) => decreaseTravels(e, 'seniors')}
                                                                            >
                                                                                <i className="fa-solid fa-minus"></i>
                                                                            </span>
                                                                            <input type="text" readOnly
                                                                                value={bookingFormValues.travellers.seniors} />
                                                                            <span
                                                                                className="up"
                                                                                onClick={(e) => increaseTravels(e, 'seniors')}
                                                                            >
                                                                                <i className="fa-solid fa-plus"></i>
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="row align-items-center">
                                                                    <div className="col-sm-6">
                                                                        <h4>
                                                                            Child <span>2-11</span>
                                                                        </h4>
                                                                    </div>
                                                                    <div className="col-sm-6 text-end">
                                                                        <div className="quantity_counter">
                                                                            <span
                                                                                className="down"
                                                                                onClick={(e) => decreaseTravels(e, 'child')}
                                                                            >
                                                                                <i className="fa-solid fa-minus"></i>
                                                                            </span>
                                                                            <input type="text" readOnly
                                                                                value={bookingFormValues.travellers.child} />
                                                                            <span
                                                                                className="up"
                                                                                onClick={(e) => increaseTravels(e, 'child')}
                                                                            >
                                                                                <i className="fa-solid fa-plus"></i>
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="row align-items-center">
                                                                    <div className="col-sm-6">
                                                                        <h4>
                                                                            Infant In Seat <span>0-2</span>
                                                                        </h4>
                                                                    </div>
                                                                    <div className="col-sm-6 text-end">
                                                                        <div className="quantity_counter">
                                                                            <span
                                                                                className="down"
                                                                                onClick={(e) => decreaseTravels(e, 'infant_in_seat')}
                                                                            >
                                                                                <i className="fa-solid fa-minus"></i>
                                                                            </span>
                                                                            <input type="text" readOnly
                                                                                value={bookingFormValues.travellers.infant_in_seat} />
                                                                            <span
                                                                                className="up"
                                                                                onClick={(e) => increaseTravels(e, 'infant_in_seat')}
                                                                            >
                                                                                <i className="fa-solid fa-plus"></i>
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="row align-items-center">
                                                                    <div className="col-sm-6">
                                                                        <h4>
                                                                            Infant In Lap <span>0-2</span>
                                                                        </h4>
                                                                    </div>
                                                                    <div className="col-sm-6 text-end">
                                                                        <div className="quantity_counter">
                                                                            <span
                                                                                className="down"
                                                                                onClick={(e) => decreaseTravels(e, 'infant_in_lap')}
                                                                            >
                                                                                <i className="fa-solid fa-minus"></i>
                                                                            </span>
                                                                            <input type="text" readOnly
                                                                                value={bookingFormValues.travellers.infant_in_lap} />
                                                                            <span
                                                                                className="up"
                                                                                onClick={(e) => increaseTravels(e, 'infant_in_lap')}
                                                                            >
                                                                                <i className="fa-solid fa-plus"></i>
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                {bookingFormValues.totalTravellers > bookingFormValues.limitTravelers && (
                                                                    <div className="travellers-error-msg">
                                                                        We are only able to book between 1 and 6
                                                                        travelers.
                                                                        <br />
                                                                        If you need more travelers please contact us:
                                                                        <div
                                                                            className={"travellers-error-msg__contacts"}>
                                                                            <span>(212) 680-4905</span>
                                                                            <span>info@flightinsiders.com</span>
                                                                        </div>
                                                                    </div>
                                                                )}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-sm-12">
                                                    <button
                                                        type="button"
                                                        className="btn"
                                                        data-bs-toggle="modal"
                                                        data-bs-target="#flightBookingModal"
                                                        onClick={handleBookingFormNext}
                                                        disabled={nextButtonDisabled}
                                                    >
                                                        Get Quote
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    ) : (
                                        <div className="two_way_form">
                                            <div ref={multiBlock} className="two_way_form_body">
                                                {bookingFormValues.trip_cities.map((city, index) => (
                                                    <div key={index}>
                                                        <div id="FlightRowForm" className="row">
                                                            <div className="col-sm-6 mb-3">
                                                                <h2>{`Flight ${index + 1}`}</h2>
                                                            </div>
                                                            <div className="col-sm-6 text-end mb-3">
                                                                {bookingFormValues.trip_cities?.length > 1 && (
                                                                    <button type="link"
                                                                        onClick={() => handleDeleteFlight(index)}
                                                                        className="removeFilghtRow">
                                                                        Remove
                                                                    </button>
                                                                )}
                                                            </div>
                                                            <div className="col-sm-12">
                                                                <div className="directions_box directions_box2">
                                                                    <div className="row align-items-center">
                                                                        <div className="col-sm-5">
                                                                            <div
                                                                                className={`input-group ${activeSearchBoxIndex !== index ? "hideSearchBox" : "showSearchBox"}`}>
                                                                                <span className="input-group-text">
                                                                                    <img src="images/fi1.svg"
                                                                                        alt="Plane" />
                                                                                </span>
                                                                                <input
                                                                                    name="from_airport"
                                                                                    autoComplete="off"
                                                                                    type="text"
                                                                                    className="form-control"
                                                                                    placeholder="From"
                                                                                    required
                                                                                    value={bookingFormValues.trip_cities[index].from_airport.name}
                                                                                    onChange={(e) => FromAirportChangeHandler(e, index)}
                                                                                />
                                                                                {FromAirPortRenderAutocomplete(index)}
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-sm-2 text-center">
                                                                            <button
                                                                                type="button"
                                                                                className="btn"
                                                                                data-bs-toggle="tooltip"
                                                                                data-bs-placement="top"
                                                                                title="Reverse From and To"
                                                                                onClick={() => toggleDestinationsMultiCity(index)}
                                                                            >
                                                                                <img src="images/switch_location.png"
                                                                                    alt="Switch" />
                                                                            </button>
                                                                        </div>
                                                                        <div className="col-sm-5 to-airport-box">
                                                                            <div
                                                                                className={`input-group ${activeSearchBoxIndex !== index ? "hideSearchBox" : "showSearchBox"}`}>
                                                                                <span className="input-group-text">
                                                                                    <img src="images/fi2.svg" alt="Plane" />
                                                                                </span>
                                                                                <input
                                                                                    name="to_airport"
                                                                                    autoComplete="off"
                                                                                    type="text"
                                                                                    className="form-control"
                                                                                    placeholder="To"
                                                                                    required
                                                                                    onChange={(e) => ToAirPortChangeHandler(e, index)}
                                                                                    value={bookingFormValues.trip_cities[index].to_airport.name}
                                                                                />
                                                                                {ToAirPortRenderAutocomplete(index)}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    {bookingFormValues.trip_cities[index].from_airport.id !== -1 && bookingFormValues.trip_cities[index].to_airport.id !== -1 && (bookingFormValues.trip_cities[index].from_airport.iata_code === bookingFormValues.trip_cities[index].to_airport.iata_code) &&
                                                                        <div className="row mt-2" style={{ "color": "red" }}>
                                                                            <span>Please choose different destinations</span>
                                                                        </div>
                                                                    }
                                                                </div>
                                                            </div>
                                                            <div className="col-sm-12">
                                                                <div className="form-floating departure-datebox">
                                                                    {/*<input
                                                                        className="form-control datedropper"
                                                                        id="departure"
                                                                        min={bookingFormValues.minDate}
                                                                        max={bookingFormValues.return_date || bookingFormValues.maxDate}
                                                                        placeholder="Departure"
                                                                        type="date"
                                                                        required
                                                                        onChange={(e) => {
                                                                            bookingFormValues.trip_cities[parseInt(index)].departure_date = e.target.value;
                                                                            setBookingFormValues({
                                                                                ...bookingFormValues,
                                                                            });
                                                                        }}
                                                                    />
                                                                    <label htmlFor="departure">Departure Date</label>*/}
                                                                    <DatePicker
                                                                        dateFormat="MM/dd/yyyy"
                                                                        minDate={new Date(bookingFormValues.minDate)}
                                                                        maxDate={new Date(bookingFormValues.maxDate)}
                                                                        className="departure-input"
                                                                        placeholderText="mm/dd/yyyy"
                                                                        selected={!bookingFormValues.trip_cities[parseInt(index)].departure_date ? null : new Date(bookingFormValues.trip_cities[parseInt(index)].departure_date)}
                                                                        popperPlacement="bottom"
                                                                        onChange={(date) => {
                                                                            bookingFormValues.trip_cities[parseInt(index)].departure_date = formatDate(date);
                                                                            setBookingFormValues({
                                                                                ...bookingFormValues,
                                                                            });
                                                                        }}
                                                                        popperModifiers={[
                                                                            {
                                                                                name: "offset",
                                                                                options: {
                                                                                    offset: [0, 8],
                                                                                },
                                                                            },
                                                                            {
                                                                                name: "preventOverflow",
                                                                                options: {
                                                                                    // rootBoundary: multiBlock,
                                                                                    rootBoundary: "viewport",
                                                                                    tether: false,
                                                                                    altAxis: true,
                                                                                },
                                                                            },
                                                                        ]}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                ))}

                                                <div className="row justify-content-around">
                                                    <div className="col-sm-12 col-md-12 mb-3">
                                                        {bookingFormValues.trip_cities?.length < 3 && (
                                                            <button
                                                                type="link"
                                                                id="addFlightRow"
                                                                onClick={handleAddAnotherFlight}
                                                                className="addFlightRow"
                                                                style={{
                                                                    marginTop: "16px !important",
                                                                }}
                                                            >
                                                                + Add Another Flight
                                                            </button>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                            <div
                                                className="row align-items-center justify-content-between two_way_form_bottom_button">
                                                <div className="col-sm-8">
                                                    <div className="dropdown travellers_dropdown">
                                                        <button
                                                            className="btn btn-secondary dropdown-toggle"
                                                            type="button"
                                                            id="travellers_dropdown"
                                                            data-bs-toggle="dropdown"
                                                            data-bs-auto-close="outside"
                                                            aria-expanded="false"
                                                            onClick={travellersBtnHandler}
                                                        >
                                                            <h6>Passengers</h6>
                                                            <h5>
                                                                <span className="pass_span"
                                                                    style={{
                                                                        color: bookingFormValues.totalTravellers >= 6 ? "red" : "",
                                                                    }}
                                                                >
                                                                    {bookingFormValues.totalTravellers}
                                                                    {""} Passengers {" "}
                                                                </span>
                                                                <span
                                                                    style={{
                                                                        color: isErrorTravellers ? "red" : "",
                                                                    }}
                                                                >
                                                                    {bookingFormValues.class.name}
                                                                </span>
                                                            </h5>
                                                        </button>
                                                        <div className="dropdown-menu"
                                                            aria-labelledby="travellers_dropdown">
                                                            <div className="form-floating">
                                                                <select
                                                                    className="form-select"
                                                                    id="class"
                                                                    value={bookingFormValues.class.id}
                                                                    onChange={(e) => {
                                                                        let index = e.nativeEvent.target.selectedIndex;
                                                                        let label = e.nativeEvent.target[index].text;
                                                                        handleBookingFormChange(e, {
                                                                            class: {
                                                                                id: e.currentTarget.value,
                                                                                name: label,
                                                                            },
                                                                        });
                                                                    }}
                                                                >
                                                                    {/* <option value="">Select Flight Class</option> */}
                                                                    <option value="-1" disabled>Select Flight
                                                                        Class
                                                                    </option>
                                                                    {formData?.flight_classes?.map((flight) => (
                                                                        <option value={flight.id} key={flight?.id}>
                                                                            {flight?.name}
                                                                        </option>
                                                                    ))}
                                                                </select>
                                                                <label htmlFor="classSelect">Cabin Class</label>
                                                            </div>
                                                            <div className="quantity_selection_box">
                                                                <div className="row align-items-center">
                                                                    <div className="col-sm-6">
                                                                        <h4>
                                                                            Adult <span>12-64</span>
                                                                        </h4>
                                                                    </div>
                                                                    <div className="col-sm-6 text-end">
                                                                        <div className="quantity_counter">
                                                                            <span
                                                                                className="down"
                                                                                onClick={(e) => decreaseTravels(e, 'adult')}
                                                                            >
                                                                                <i className="fa-solid fa-minus"></i>
                                                                            </span>
                                                                            <input type="text" readOnly
                                                                                value={bookingFormValues.travellers.adult} />
                                                                            <span
                                                                                className="up"
                                                                                onClick={(e) => increaseTravels(e, 'adult')}
                                                                            >
                                                                                <i className="fa-solid fa-plus"></i>
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                <div className="row align-items-center">
                                                                    <div className="col-sm-6">
                                                                        <h4>
                                                                            Seniors <span>65+</span>
                                                                        </h4>
                                                                    </div>
                                                                    <div className="col-sm-6 text-end">
                                                                        <div className="quantity_counter">
                                                                            <span
                                                                                className="down"
                                                                                onClick={(e) => decreaseTravels(e, 'seniors')}
                                                                            >
                                                                                <i className="fa-solid fa-minus"></i>
                                                                            </span>
                                                                            <input type="text" readOnly
                                                                                value={bookingFormValues.travellers.seniors} />
                                                                            <span
                                                                                className="up"
                                                                                onClick={(e) => increaseTravels(e, 'seniors')}
                                                                            >
                                                                                <i className="fa-solid fa-plus"></i>
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                <div className="row align-items-center">
                                                                    <div className="col-sm-6">
                                                                        <h4>
                                                                            Child <span>2-11</span>
                                                                        </h4>
                                                                    </div>
                                                                    <div className="col-sm-6 text-end">
                                                                        <div className="quantity_counter">
                                                                            <span
                                                                                className="down"
                                                                                onClick={(e) => decreaseTravels(e, 'child')}
                                                                            >
                                                                                <i className="fa-solid fa-minus"></i>
                                                                            </span>
                                                                            <input type="text" readOnly
                                                                                value={bookingFormValues.travellers.child} />
                                                                            <span
                                                                                className="up"
                                                                                onClick={(e) => increaseTravels(e, 'child')}
                                                                            >
                                                                                <i className="fa-solid fa-plus"></i>
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="row align-items-center">
                                                                    <div className="col-sm-6">
                                                                        <h4>
                                                                            Infant In Seat <span>0-2</span>
                                                                        </h4>
                                                                    </div>
                                                                    <div className="col-sm-6 text-end">
                                                                        <div className="quantity_counter">
                                                                            <span
                                                                                className="down"
                                                                                onClick={(e) => decreaseTravels(e, 'infant_in_seat')}
                                                                            >
                                                                                <i className="fa-solid fa-minus"></i>
                                                                            </span>
                                                                            <input type="text" readOnly
                                                                                value={bookingFormValues.travellers.infant_in_seat} />
                                                                            <span
                                                                                className="up"
                                                                                onClick={(e) => increaseTravels(e, 'infant_in_seat')}
                                                                            >
                                                                                <i className="fa-solid fa-plus"></i>
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="row align-items-center">
                                                                    <div className="col-sm-6">
                                                                        <h4>
                                                                            Infant In Lap <span>0-2</span>
                                                                        </h4>
                                                                    </div>
                                                                    <div className="col-sm-6 text-end">
                                                                        <div className="quantity_counter">
                                                                            <span
                                                                                className="down"
                                                                                onClick={(e) => decreaseTravels(e, 'infant_in_lap')}
                                                                            >
                                                                                <i className="fa-solid fa-minus"></i>
                                                                            </span>
                                                                            <input type="text" readOnly
                                                                                value={bookingFormValues.travellers.infant_in_lap} />
                                                                            <span
                                                                                className="up"
                                                                                onClick={(e) => increaseTravels(e, 'infant_in_lap')}
                                                                            >
                                                                                <i className="fa-solid fa-plus"></i>
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                {bookingFormValues.totalTravellers > bookingFormValues.limitTravelers && (
                                                                    <div className="travellers-error-msg">
                                                                        We are only able to book between 1 and 6
                                                                        travelers.
                                                                        <br />
                                                                        If you need more travelers please contact us:
                                                                        <div
                                                                            className={"travellers-error-msg__contacts"}>
                                                                            <span>(212) 680-4905</span>
                                                                            <span>info@flightinsiders.com</span>
                                                                        </div>
                                                                    </div>
                                                                )}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-sm-4 float-right">
                                                    <button
                                                        type="button"
                                                        className="btn mt-2"
                                                        data-bs-toggle="modal"
                                                        data-bs-target="#flightBookingModal"
                                                        disabled={multiCityNextButtonDisabled}
                                                    >
                                                        Get Quote
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* <!-- Flight Booking Modal --> */}
                    <div
                        className="modal fade custom_modal"
                        id="flightBookingModal"
                        tabIndex="-1"
                        style={{ zIndex: 99999 }}
                        aria-labelledby="flightBookingModalLabel"
                        aria-hidden="true"
                    >
                        <div className="modal-dialog modal-fullscreen">
                            <div className="modal-content">
                                {!fetching && (
                                    <div className="container">
                                        <div className="modal-header">
                                            <div className="modal_logo"></div>
                                            <button type="button" className="btn-close" data-bs-dismiss="modal"
                                                aria-label="Close"></button>
                                        </div>

                                        <div className="modal-body">
                                            <div className="detailModalContainer">
                                                <div className="modalContainer">
                                                    <div className="header">
                                                        <div className="logo">
                                                            <img src="images/flight_logo_white.png" alt="logo" />
                                                        </div>
                                                        <div className="passInfo">Boarding Pass Info</div>
                                                        <div className="class">
                                                            <div className="label">Class</div>
                                                            <div className="valueHolder">
                                                                <span
                                                                    className="value">{bookingFormValues?.class?.name}</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="contentArea">
                                                        <div className="bar">
                                                            <img src="images/detail_page_bar.png" alt="bar" />
                                                        </div>
                                                        <div
                                                            className="content"
                                                            style={{
                                                                backgroundImage: `url("images/detail_modal_bg.png")`,
                                                                backgroundPosition: "left",
                                                                backgroundRepeat: "no-repeat",
                                                            }}
                                                        >
                                                            <div
                                                                className="bgMix"
                                                                style={{
                                                                    backgroundImage: "linear-gradient(transparent, #02529C)",
                                                                }}
                                                            >
                                                                {bookingFormValues?.trip_type === 3 ? (
                                                                    <>
                                                                        <div className="routeContainer">
                                                                            {bookingFormValues.trip_cities.map((city, index) => (
                                                                                <div className="route" key={index}>
                                                                                    <div
                                                                                        className="number">{index + 1}</div>
                                                                                    <div className="from">
                                                                                        <div className="title"
                                                                                            title={city?.from_airport?.name}>
                                                                                            {city?.from_airport?.name}
                                                                                            {/* {city?.from_airport?.name?.substr(1, 30)}

                                              {city?.from_airport?.name?.length > 30 ? "..." : ""} */}
                                                                                        </div>
                                                                                        <div
                                                                                            className="code">{city.from_airport.iata_code}</div>
                                                                                    </div>
                                                                                    <div className="from_to">
                                                                                        <img src="images/from_to.png"
                                                                                            alt="from_to" />
                                                                                    </div>
                                                                                    <div className="to">
                                                                                        <div className="title"
                                                                                            title={city?.to_airport?.name}>
                                                                                            {city?.to_airport?.name}
                                                                                            {/* {city?.to_airport?.name?.substr(1, 30)}

                                              {city?.to_airport?.name?.length > 30 ? "..." : ""} */}
                                                                                        </div>
                                                                                        <div
                                                                                            className="code">{city?.to_airport?.iata_code}</div>
                                                                                    </div>
                                                                                    <div className="date">
                                                                                        <div className="label">Outbound
                                                                                            Date
                                                                                        </div>
                                                                                        <div
                                                                                            className="value">{city?.departure_date}</div>
                                                                                    </div>
                                                                                </div>
                                                                            ))}
                                                                        </div>
                                                                    </>
                                                                ) : (
                                                                    <>
                                                                        <div className="route">
                                                                            <div className="from">
                                                                                <div className="title"
                                                                                    title={bookingFormValues?.from_airport?.name}>
                                                                                    {bookingFormValues?.from_airport?.name}
                                                                                    {/* {bookingFormValues?.from_airport?.name?.substr(1, 30)}

                                          {bookingFormValues?.from_airport?.name?.length > 30 ? "..." : ""} */}
                                                                                </div>

                                                                                <div
                                                                                    className="code">{bookingFormValues?.from_airport?.iata_code}</div>
                                                                            </div>
                                                                            <div className="from_to">
                                                                                <img src="images/from_to.png"
                                                                                    alt="from_to" />
                                                                            </div>
                                                                            <div className="to">
                                                                                <div className="title"
                                                                                    title={bookingFormValues?.to_airport?.name}>
                                                                                    {bookingFormValues?.to_airport?.name}
                                                                                    {/* {bookingFormValues?.to_airport?.name?.substr(1, 30)}

                                          {bookingFormValues?.to_airport?.name?.length > 30 ? "..." : ""} */}
                                                                                </div>

                                                                                <div
                                                                                    className="code">{bookingFormValues?.to_airport?.iata_code}</div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="dates">
                                                                            <div className="from">
                                                                                <div className="label">Outbound Date
                                                                                </div>
                                                                                <div
                                                                                    className="value">{custom_formatted_date(bookingFormValues?.departure_date)}</div>
                                                                            </div>
                                                                            <div className="from_to"></div>
                                                                            {bookingFormValues?.trip_type === 2 ? (
                                                                                <div className="to">
                                                                                    <div className="label">Return Date
                                                                                    </div>
                                                                                    <div
                                                                                        className="value">{custom_formatted_date(bookingFormValues?.return_date)}</div>
                                                                                </div>
                                                                            ) : null}
                                                                        </div>
                                                                    </>
                                                                )}
                                                                <div className="travelerDetail">
                                                                    <div className="from">
                                                                        <div className="label">Passengers</div>
                                                                        <div className="value">
                                                                            {showTravellersCustom(bookingFormValues?.travellers)}
    {/* 
    {showTravellers(bookingFormValues?.travellers?.seniors, "Senior", true)}
    {showTravellers(bookingFormValues?.travellers?.adult, "Adult", true)}
    {showTravellers(bookingFormValues?.travellers?.child, "Child", true)}
    {showTravellers(bookingFormValues?.travellers?.infant_in_seat, "Infant in seat", false)}
    {showTravellers(bookingFormValues?.travellers?.infant_in_lap, "Infant in lap", false)} 
    */}
                                                                        </div>
                                                                    </div>
                                                                    <div className="from_to"></div>
                                                                    <div className="to">
                                                                        <div className="label"></div>
                                                                        {bookingFormValues.trip_type === 2 ? (
                                                                            <div className="value">
                                                                                Round Trip <img
                                                                                    src="images/round_trip_icon.svg"
                                                                                    alt="round_trip" />
                                                                            </div>
                                                                        ) : bookingFormValues.trip_type === 1 ? (
                                                                            <div className="value">
                                                                                One Way <img
                                                                                    src="images/one_way_trip_icon.png"
                                                                                    alt="one_way_trip" />
                                                                            </div>
                                                                        ) : (
                                                                            <div className="value">
                                                                                Multi-City <img
                                                                                    src="images/multi_city_trip_icon.svg"
                                                                                    alt="multicity_trip" />
                                                                            </div>
                                                                        )}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="heading">Please share your contact Information</div>

                                                <div className="formContainer">
                                                    <div className="form-floating">
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            id="first_name"
                                                            name="first_name"
                                                            placeholder="Name"
                                                            required
                                                            value={bookingFormValues.first_name}
                                                            onChange={(e) =>
                                                                handleBookingFormChange(e, {
                                                                    first_name: e.target.value,
                                                                })
                                                            }
                                                        />

                                                        <div
                                                            style={{
                                                                color: "red",
                                                            }}
                                                        >
                                                            {renderErrorMessage("first_name")}
                                                        </div>
                                                        <label htmlFor="name">First Name</label>
                                                    </div>

                                                    <div className="form-floating">
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            id="last_name"
                                                            name="last_name"
                                                            placeholder="Name"
                                                            required
                                                            value={bookingFormValues.last_name}
                                                            onChange={(e) =>
                                                                handleBookingFormChange(e, {
                                                                    last_name: e.target.value,
                                                                })
                                                            }
                                                        />

                                                        <div
                                                            style={{
                                                                color: "red",
                                                            }}
                                                        >
                                                            {renderErrorMessage("last_name")}
                                                        </div>
                                                        <label htmlFor="name">Last Name</label>
                                                    </div>

                                                    <div className="form-floating">
                                                        <input
                                                            type="email"
                                                            className="form-control"
                                                            id="email"
                                                            placeholder="Email"
                                                            required
                                                            value={bookingFormValues.email}
                                                            onChange={(e) =>
                                                                handleBookingFormChange(e, {
                                                                    email: e.target.value,
                                                                })
                                                            }
                                                        // {...finalSubmission.getFieldProps("email")}
                                                        />
                                                        <div
                                                            style={{
                                                                color: "red",
                                                            }}
                                                        >
                                                            {renderErrorMessage("email")}
                                                        </div>
                                                        <label htmlFor="email">Email</label>
                                                    </div>
                                                    <div className="form-floating">
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            id="phone"
                                                            placeholder="Phone"
                                                            required
                                                            value={bookingFormValues.phone}
                                                            onKeyPress={(event) => {
                                                                if (!/[0-9]/.test(event.key)) {
                                                                    event.preventDefault();
                                                                }
                                                            }}
                                                            onChange={(e) =>
                                                                handleBookingFormChange(e, {
                                                                    phone: e.target.value,
                                                                })
                                                            }
                                                        // {...finalSubmission.getFieldProps("phone")}
                                                        />
                                                        <div
                                                            style={{
                                                                color: "red",
                                                            }}
                                                        >
                                                            {renderErrorMessage("phone")}
                                                        </div>
                                                        <label htmlFor="phone">Phone</label>
                                                    </div>
                                                    <div>
                                                        <button
                                                            type="submit"
                                                            className="btn greenBtn"
                                                            data-bs-toggle="modal"
                                                            // data-bs-target="#thankYouModal"
                                                            // disabled={!isEmpty(finalSubmission.errors)}
                                                            disabled={submitButtonDisabled}
                                                            onClick={finalSubmission.handleSubmit}
                                                        >
                                                            Submit
                                                        </button>
                                                    </div>
                                                </div>

                                                <div className="checkBox">
                                                    <div className="form-check">
                                                        <input
                                                            className="form-check-input"
                                                            type="checkbox"
                                                            defaultChecked
                                                            required
                                                            id="accommodation"
                                                            value={finalSubmission.values.accommodation}
                                                            onChange={finalSubmission.onChange}
                                                        />
                                                        <label className="form-check-label" htmlFor="accommodation">
                                                            Check accommodation with FlightInsiders.com
                                                        </label>
                                                    </div>
                                                </div>

                                                <div className="privacyContainer">
                                                    <div className="text">Your privacy is protected by</div>
                                                    <div className="icon">
                                                        <img src="images/gdpr.png" alt="GDPR" />
                                                    </div>
                                                    <div className="icon">
                                                        <img src="images/ccpa.png" alt="CCPA" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>

    {/* <!-- Thank You Modal --> */}
    {/* {showSuccessModal === true && !isMobile ? <ThankyouWeb setShowSuccessModal={setShowSuccessModal} showSuccessModal={showSuccessModal} /> : null} */}
                </div>
            </div>

    {/* <!-- Mobile Flight Booking Modal --> */}
            <div
                className="modal fade custom_modal3 custom_modal5"
                id="mobileFlightBookingModal"
                tabIndex="-1"
                aria-labelledby="mobileFlightBookingModalLabel"
                aria-hidden="true"
            >
                <div className="modal-dialog modal-fullscreen mobile-lead-form">
                    <div className="modal-content">
                        <div className="modal-header">
                            <div style={{ display: "flex", alignItems: "center" }}>
                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close">
                                    <img src="images/back_arrow.png" alt="" />
                                </button>
                                <h2 style={{ marginTop: "0px", marginLeft: "20px" }}>Flight Info</h2>
                            </div>
                            <div className="row mt-5">
                                <div className="col-sm-12">
                                    <div className="form-check form-check-inline mobile_radio_btn">
                                        <input
                                            className="form-check-input"
                                            id="trip_type5"
                                            type="radio"
                                            name="trip_type_mobile"
                                            checked={bookingFormValues?.trip_type === 2}
                                            onChange={(e) =>
                                                handleBookingFormChange(e, {
                                                    trip_type: 2,
                                                    departure_date: "",
                                                    return_date: "",
                                                    from_airport: { id: -1, name: "" },
                                                    to_airport: { id: -1, name: "" },
                                                }, 'mode')
                                            }
                                        />
                                        <label className="form-check-label" htmlFor="trip_type5">
                                            Round Trip
                                        </label>
                                    </div>
                                    <div className="form-check form-check-inline mobile_radio_btn">
                                        <input
                                            className="form-check-input"
                                            id="trip_type4"
                                            type="radio"
                                            name="trip_type_mobile"
                                            checked={bookingFormValues?.trip_type === 1}
                                            onChange={(e) =>
                                                handleBookingFormChange(e, {
                                                    trip_type: 1,
                                                    departure_date: "",
                                                    return_date: "",
                                                    from_airport: { id: -1, name: "" },
                                                    to_airport: { id: -1, name: "" },
                                                }, 'mode')
                                            }
                                        />
                                        <label className="form-check-label" htmlFor="trip_type4">
                                            One Way
                                        </label>
                                    </div>

                                    <div className="form-check form-check-inline mobile_radio_btn">
                                        <input
                                            className="form-check-input"
                                            id="trip_type6"
                                            type="radio"
                                            name="trip_type_mobile"
                                            checked={bookingFormValues?.trip_type === 3}
                                            onChange={(e) =>
                                                handleBookingFormChange(e, {
                                                    trip_type: 3,
                                                    departure_date: "",
                                                    return_date: "",
                                                }, 'mode')
                                            }
                                        />
                                        <label className="form-check-label" htmlFor="trip_type6">
                                            Multi-City
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="modal-body">
                            <div className="banner_form">
                                {bookingFormValues.trip_type === 1 || bookingFormValues.trip_type === 2 ? (
                                    <div className="one_way_form_mobile">
                                        <div className="directions_box">
                                            <div className="row align-items-center">
                                                <div className="col-sm-2">
                                                    <label htmlFor="from_dest">From</label>
                                                    <div>
                                                        <img src="images/fi1.svg" alt="Plane" />
                                                    </div>
                                                </div>
                                                <div className="col-sm-10">
                                                    <input
                                                        type="text"
                                                        autoComplete="off"
                                                        className="form-control"
                                                        id="from_dest"
                                                        value={bookingFormValues.from_airport.name}
                                                        // onKeyDown={FromAirPortOnKeyDown}
                                                        onChange={FromAirportChangeHandler}
                                                    />
                                                    {FromAirPortRenderAutocomplete()}
                                                </div>
                                            </div>
                                            <div className="row align-items-center">
                                                <div className="col-sm-10">
                                                    <hr />
                                                </div>
                                                <div className="col-sm-2">
                                                    <button type="button"
                                                        className="btn"
                                                        data-bs-toggle="tooltip"
                                                        data-bs-placement="left"
                                                        title="Reverse From and To"
                                                        onClick={() => toggleDestinations()}
                                                    >
                                                        <img src="images/switch_location.png" alt="Switch" />
                                                    </button>
                                                </div>
                                            </div>
                                            <div className="row align-items-center">
                                                <div className="col-sm-2">
                                                    <label htmlFor="to_dest">To</label>
                                                    <div>
                                                        <img src="images/fi2.svg" alt="Plane" />
                                                    </div>
                                                </div>
                                                <div className="col-sm-10">
                                                    <input
                                                        type="text"
                                                        autoComplete="off"
                                                        className="form-control"
                                                        id="to_dest"
                                                        onChange={ToAirPortChangeHandler}
                                                        // onKeyDown={ToAirPortOnKeyDown}
                                                        value={bookingFormValues.to_airport.name}
                                                    />
                                                    {ToAirPortRenderAutocomplete()}
                                                </div>
                                            </div>
                                            {bookingFormValues.from_airport.id !== -1 && bookingFormValues.to_airport.id !== -1 && bookingFormValues.from_airport.id === bookingFormValues.to_airport.id &&
                                                <div className="row mt-2" style={{ "color": "red" }}>
                                                    <span>Please choose different destinations</span>
                                                </div>
                                            }
                                        </div>
                                        <div className="row">
                                            <div
                                                className={bookingFormValues.trip_type === 1 ? "col-sm-12" : "col-sm-6"}>
                                                <div className="form-floating departure-datebox">
                                                    {/*<input
                                                        type="date"
                                                        className="form-control datedropper"
                                                        id="departure"
                                                        min={bookingFormValues.minDate}
                                                        max={bookingFormValues.return_date || bookingFormValues.maxDate}
                                                        placeholder="Departure"
                                                        value={bookingFormValues.departure_date}
                                                        onChange={(e) =>
                                                            handleBookingFormChange(e, {
                                                                departure_date: e.target.value,
                                                            })
                                                        }
                                                    />
                                                    <label htmlFor="departure">Departure</label>*/}
                                                    <DatePicker
                                                        onFocus={(e) => e.target.readOnly = true}
                                                        dateFormat="MM/dd/yyyy"
                                                        minDate={new Date(bookingFormValues.minDate)}
                                                        maxDate={detectMaxDepartureDate(bookingFormValues.return_date)}
                                                        className="departure-input"
                                                        placeholderText="mm/dd/yyyy"
                                                        selected={!bookingFormValues.departure_date ? null : new Date(bookingFormValues.departure_date)}
                                                        onChange={(date) =>
                                                            handleBookingFormChange(date, {
                                                                departure_date: formatDate(date),
                                                            })
                                                        }
                                                    />
                                                </div>
                                            </div>
                                            <div className={bookingFormValues?.trip_type === 1 ? "d-none" : "col-sm-6"}>
                                                <div className="form-floating return-datebox">
                                                    {/*<input
                                                        type="date"
                                                        className="form-control datedropper"
                                                        id="return"
                                                        placeholder="Return"
                                                        value={bookingFormValues.return_date}
                                                        onChange={(e) =>
                                                            handleBookingFormChange(e, {
                                                                return_date: e.target.value,
                                                            })
                                                        }
                                                        min={bookingFormValues.departure_date || bookingFormValues.minDate}
                                                        max={bookingFormValues.maxDate}
                                                    />
                                                    <label htmlFor="return">Return</label>*/}
                                                    <DatePicker
                                                        onFocus={(e) => e.target.readOnly = true}
                                                        dateFormat="MM/dd/yyyy"
                                                        minDate={detectMinReturnDate(bookingFormValues.departure_date)}
                                                        maxDate={new Date(bookingFormValues.maxDate)}
                                                        className="return-input"
                                                        placeholderText="mm/dd/yyyy"
                                                        selected={!bookingFormValues.return_date ? null : new Date(bookingFormValues.return_date)}
                                                        onChange={(date) =>
                                                            handleBookingFormChange(date, {
                                                                return_date: formatDate(date),
                                                            })
                                                        }
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-sm-12">
                                                <div className="dropup travellers_dropdown">
                                                    <button
                                                        className="btn btn-secondary dropdown-toggle"
                                                        type="button"
                                                        id="travellers_dropdown"
                                                        data-bs-toggle="dropdown"
                                                        data-bs-auto-close="outside"
                                                        aria-expanded="false"
                                                        onClick={travellersBtnHandler}
                                                    >
                                                        <h6>Passengers</h6>
                                                        <h5>
                                                            <span className="pass_span"
                                                                style={{
                                                                    color: bookingFormValues.totalTravellers > bookingFormValues.limitTravelers ? "red" : "",
                                                                }}
                                                            >
                                                                {bookingFormValues.totalTravellers} Passengers
                                                            </span>
                                                            <span
                                                                style={{
                                                                    color: isErrorTravellers ? "red" : "",
                                                                }}
                                                            >
                                                                {bookingFormValues.class.name}
                                                            </span>
                                                        </h5>
                                                    </button>
                                                    <div className="dropdown-menu"
                                                        aria-labelledby="travellers_dropdown">
                                                        <div className="form-floating">
                                                            <select
                                                                className="form-select"
                                                                id="classSelect"
                                                                value={bookingFormValues.class.id}
                                                                onChange={(e) => {
                                                                    let index = e.nativeEvent.target.selectedIndex;
                                                                    let label = e.nativeEvent.target[index].text;
                                                                    handleBookingFormChange(e, {
                                                                        class: {
                                                                            id: e.currentTarget.value,
                                                                            name: label,
                                                                        },
                                                                    });
                                                                }}
                                                            >
                                                                {/* <option value="">Select Flight Class</option> */}
                                                                <option value="-1" disabled>Select Flight Class
                                                                </option>
                                                                {formData?.flight_classes?.map((flight) => (
                                                                    <option value={flight.id} key={flight?.id}>
                                                                        {flight?.name}
                                                                    </option>
                                                                ))}
                                                            </select>
                                                            <label htmlFor="classSelect">Cabin Class</label>
                                                        </div>

                                                        <div className="quantity_selection_box">
                                                            <div className="row align-items-center">
                                                                <div className="col-sm-6">
                                                                    <h4>
                                                                        Adult <span>12-64</span>
                                                                    </h4>
                                                                </div>
                                                                <div className="col-sm-6 text-end">
                                                                    <div className="quantity_counter">
                                                                        <span
                                                                            className="down"
                                                                            onClick={(e) => decreaseTravels(e, 'adult')}
                                                                        >
                                                                            <i className="fa-solid fa-minus"></i>
                                                                        </span>
                                                                        <input type="text" readOnly
                                                                            value={bookingFormValues.travellers.adult} />
                                                                        <span
                                                                            className="up"
                                                                            onClick={(e) => increaseTravels(e, 'adult')}
                                                                        >
                                                                            <i className="fa-solid fa-plus"></i>
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div className="row align-items-center">
                                                                <div className="col-sm-6">
                                                                    <h4>
                                                                        Seniors <span>65+</span>
                                                                    </h4>
                                                                </div>
                                                                <div className="col-sm-6 text-end">
                                                                    <div className="quantity_counter">
                                                                        <span
                                                                            className="down"
                                                                            onClick={(e) => decreaseTravels(e, 'seniors')}
                                                                        >
                                                                            <i className="fa-solid fa-minus"></i>
                                                                        </span>
                                                                        <input type="text" readOnly
                                                                            value={bookingFormValues.travellers.seniors} />
                                                                        <span
                                                                            className="up"
                                                                            onClick={(e) => increaseTravels(e, 'seniors')}
                                                                        >
                                                                            <i className="fa-solid fa-plus"></i>
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div className="row align-items-center">
                                                                <div className="col-sm-6">
                                                                    <h4>
                                                                        Child <span>2-11</span>
                                                                    </h4>
                                                                </div>
                                                                <div className="col-sm-6 text-end">
                                                                    <div className="quantity_counter">
                                                                        <span
                                                                            className="down"
                                                                            onClick={(e) => decreaseTravels(e, 'child')}
                                                                        >
                                                                            <i className="fa-solid fa-minus"></i>
                                                                        </span>
                                                                        <input type="text" readOnly
                                                                            value={bookingFormValues.travellers.child} />
                                                                        <span
                                                                            className="up"
                                                                            onClick={(e) => increaseTravels(e, 'child')}
                                                                        >
                                                                            <i className="fa-solid fa-plus"></i>
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div className="row align-items-center">
                                                                <div className="col-sm-6">
                                                                    <h4>
                                                                        Infant In Seat <span>0-2</span>
                                                                    </h4>
                                                                </div>
                                                                <div className="col-sm-6 text-end">
                                                                    <div className="quantity_counter">
                                                                        <span
                                                                            className="down"
                                                                            onClick={(e) => decreaseTravels(e, 'infant_in_seat')}
                                                                        >
                                                                            <i className="fa-solid fa-minus"></i>
                                                                        </span>
                                                                        <input type="text" readOnly
                                                                            value={bookingFormValues.travellers.infant_in_seat} />
                                                                        <span
                                                                            className="up"
                                                                            onClick={(e) => increaseTravels(e, 'infant_in_seat')}
                                                                        >
                                                                            <i className="fa-solid fa-plus"></i>
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div className="row align-items-center">
                                                                <div className="col-sm-6">
                                                                    <h4>
                                                                        Infant In Lap <span>0-2</span>
                                                                    </h4>
                                                                </div>
                                                                <div className="col-sm-6 text-end">
                                                                    <div className="quantity_counter">
                                                                        <span
                                                                            className="down"
                                                                            onClick={(e) => decreaseTravels(e, 'infant_in_lap')}
                                                                        >
                                                                            <i className="fa-solid fa-minus"></i>
                                                                        </span>
                                                                        <input type="text" readOnly
                                                                            value={bookingFormValues.travellers.infant_in_lap} />
                                                                        <span
                                                                            className="up"
                                                                            onClick={(e) => increaseTravels(e, 'infant_in_lap')}
                                                                        >
                                                                            <i className="fa-solid fa-plus"></i>
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            {bookingFormValues.totalTravellers > bookingFormValues.limitTravelers && (
                                                                <div className="travellers-error-msg">
                                                                    We are only able to book between 1 and 6 travelers.
                                                                    <br />
                                                                    If you need more travelers please contact us:
                                                                    <div className={"travellers-error-msg__contacts"}>
                                                                        <span>(212) 680-4905</span>
                                                                        <span>info@flightinsiders.com</span>
                                                                    </div>
                                                                </div>
                                                            )}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-sm-12">
                                                <button
                                                    type="button"
                                                    className="btn"
                                                    data-bs-toggle="modal"
                                                    data-bs-target="#mobilePersonalInfoModal"
                                                    onClick={handleBookingFormNext}
                                                    disabled={nextButtonDisabled}
                                                >
                                                    Get Quote
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                ) : (
                                    <div className="two_way_form_mobile">
                                        <div className="row justify-content-around">
                                            <div className="col-sm-5 col-md-5 mb-3"></div>
                                            <div className="col-sm-7 col-md-7 text-center mb-3">
                                                {bookingFormValues.trip_cities?.length < 3 && (
                                                    <button
                                                        type="link"
                                                        id="addFlightRow"
                                                        onClick={handleAddAnotherFlight}
                                                        className="addFlightRow"
                                                        style={{
                                                            marginTop: "20px !important",
                                                        }}
                                                    >
                                                        + Add Another Flight
                                                    </button>
                                                )}
                                            </div>
                                        </div>
                                        <div className="FlightRowForm2Scroll">
                                            {bookingFormValues.trip_cities.map((city, index) => (
                                                <div key={index}>
                                                    <div className="FlightRowForm2">
                                                        <div key={index}>
                                                            <div id="FlightRowForm2" className="row">
                                                                <div className="col-sm-6 mb-3">
                                                                    <h2>{`Flight ${index + 1}`}</h2>
                                                                </div>
                                                                <div className="col-sm-6 mb-3 text-end mb-3">
                                                                    {bookingFormValues.trip_cities?.length > 1 && (
                                                                        <button type="link"
                                                                            onClick={() => handleDeleteFlight(index)}
                                                                            className="removeFilghtRow">
                                                                            Remove
                                                                        </button>
                                                                    )}
                                                                </div>
                                                                <div className="col-sm-12">
                                                                    <div className="directions_box">
                                                                        <div className="row align-items-center">
                                                                            <div className="col-sm-2">
                                                                                <label htmlFor="from_dest">From</label>
                                                                                <div>
                                                                                    <img src="images/fi1.svg"
                                                                                        alt="Plane" />
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-sm-10">
                                                                                <input
                                                                                    type="text"
                                                                                    className="form-control"
                                                                                    autoComplete="off"
                                                                                    id="from_dest"
                                                                                    value={bookingFormValues.trip_cities[index].from_airport.name}
                                                                                    onChange={(e) => FromAirportChangeHandler(e, index)}
                                                                                />
                                                                                {FromAirPortRenderAutocomplete(index)}
                                                                            </div>
                                                                        </div>
                                                                        <div className="row align-items-center">
                                                                            <div className="col-sm-10">
                                                                                <hr />
                                                                            </div>
                                                                            <div className="col-sm-2">
                                                                                <button
                                                                                    type="button"
                                                                                    className="btn"
                                                                                    data-bs-toggle="tooltip"
                                                                                    data-bs-placement="left"
                                                                                    title="Reverse From and To"
                                                                                    onClick={() => toggleDestinationsMultiCity(index)}
                                                                                >
                                                                                    <img
                                                                                        src="images/switch_location.png"
                                                                                        alt="Switch" />
                                                                                </button>
                                                                            </div>
                                                                        </div>
                                                                        <div className="row align-items-center">
                                                                            <div className="col-sm-2">
                                                                                <label htmlFor="to_dest">To</label>
                                                                                <div>
                                                                                    <img src="images/fi2.svg"
                                                                                        alt="Plane" />
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-sm-10">
                                                                                <input
                                                                                    type="text"
                                                                                    autoComplete="off"
                                                                                    className="form-control"
                                                                                    id="to_dest"
                                                                                    onChange={(e) => ToAirPortChangeHandler(e, index)}
                                                                                    value={bookingFormValues.trip_cities[index].to_airport.name}
                                                                                />
                                                                                {ToAirPortRenderAutocomplete(index)}
                                                                            </div>
                                                                        </div>
                                                                        {bookingFormValues.trip_cities[index].from_airport.id !== -1 && bookingFormValues.trip_cities[index].to_airport.id !== -1 && (bookingFormValues.trip_cities[index].from_airport.iata_code === bookingFormValues.trip_cities[index].to_airport.iata_code) &&
                                                                            <div className="row mt-2"
                                                                                style={{ "color": "red" }}>
                                                                                <span>Please choose different destinations</span>
                                                                            </div>
                                                                        }
                                                                    </div>
                                                                </div>
                                                                <div className="col-sm-12">
                                                                    <div className="form-floating departure-datebox">
                                                                        {/*<input*/}
                                                                        {/*    type="date"*/}
                                                                        {/*    className="form-control datedropper"*/}
                                                                        {/*    id="departure"*/}
                                                                        {/*    min={bookingFormValues.minDate}*/}
                                                                        {/*    max={bookingFormValues.return_date || bookingFormValues.maxDate}*/}
                                                                        {/*    placeholder="Departure"*/}
                                                                        {/*    onChange={(e) => {*/}
                                                                        {/*        bookingFormValues.trip_cities[parseInt(index)].departure_date = e.target.value;*/}
                                                                        {/*        setBookingFormValues({*/}
                                                                        {/*            ...bookingFormValues,*/}
                                                                        {/*        });*/}
                                                                        {/*    }}*/}
                                                                        {/*/>*/}
                                                                        {/*<label htmlFor="departure">Departure</label>*/}
                                                                        <DatePicker
                                                                            onFocus={(e) => e.target.readOnly = true}
                                                                            dateFormat="MM/dd/yyyy"
                                                                            minDate={new Date(bookingFormValues.minDate)}
                                                                            maxDate={new Date(bookingFormValues.maxDate)}
                                                                            className="departure-input"
                                                                            placeholderText="mm/dd/yyyy"
                                                                            selected={!bookingFormValues.trip_cities[parseInt(index)].departure_date ? null : new Date(bookingFormValues.trip_cities[parseInt(index)].departure_date)}
                                                                            popperPlacement="bottom"
                                                                            onChange={(date) => {
                                                                                bookingFormValues.trip_cities[parseInt(index)].departure_date = formatDate(date);
                                                                                setBookingFormValues({
                                                                                    ...bookingFormValues,
                                                                                });
                                                                            }}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div id="newFlightRow2"></div>
                                                        </div>
                                                        <div className="row mt-3">
                                                            <div className="col-sm-12">
                                                                <div className="dropup travellers_dropdown">
                                                                    <button
                                                                        className="btn btn-secondary dropdown-toggle"
                                                                        type="button"
                                                                        id="travellers_dropdown"
                                                                        data-bs-toggle="dropdown"
                                                                        data-bs-auto-close="outside"
                                                                        aria-expanded="false"
                                                                        onClick={travellersBtnHandler}
                                                                    >
                                                                        <h6>Passengers</h6>
                                                                        <h5>
                                                                            <span
                                                                                style={{
                                                                                    color: bookingFormValues.totalTravellers > bookingFormValues.limitTravelers ? "red" : "",
                                                                                }}
                                                                            >
                                                                                {bookingFormValues.totalTravellers}
                                                                                {""} Travellers,
                                                                                {""}{" "}
                                                                            </span>
                                                                            <span
                                                                                style={{
                                                                                    color: isErrorTravellers ? "red" : "",
                                                                                }}
                                                                            >
                                                                                {bookingFormValues.class.name}
                                                                            </span>
                                                                        </h5>
                                                                    </button>
                                                                    <div className="dropdown-menu"
                                                                        aria-labelledby="travellers_dropdown">
                                                                        <div className="form-floating">
                                                                            <select
                                                                                className="form-select"
                                                                                id="classSelect"
                                                                                value={bookingFormValues.class.id}
                                                                                onChange={(e) => {
                                                                                    let index = e.nativeEvent.target.selectedIndex;
                                                                                    let label = e.nativeEvent.target[index].text;
                                                                                    handleBookingFormChange(e, {
                                                                                        class: {
                                                                                            id: e.currentTarget.value,
                                                                                            name: label,
                                                                                        },
                                                                                    });
                                                                                }}
                                                                            >
                                                                                {/* <option value="">Select Flight Class</option> */}
                                                                                <option value="-1" disabled>Select
                                                                                    Flight Class
                                                                                </option>
                                                                                {formData?.flight_classes?.map((flight) => (
                                                                                    <option value={flight.id}
                                                                                        key={flight?.id}>
                                                                                        {flight?.name}
                                                                                    </option>
                                                                                ))}
                                                                            </select>
                                                                            <label htmlFor="classSelect">Cabin
                                                                                Class</label>
                                                                        </div>

                                                                        <div className="quantity_selection_box">
                                                                            <div className="row align-items-center">
                                                                                <div className="col-sm-6">
                                                                                    <h4>
                                                                                        Adult <span>12-64</span>
                                                                                    </h4>
                                                                                </div>
                                                                                <div className="col-sm-6 text-end">
                                                                                    <div className="quantity_counter">
                                                                                        <span
                                                                                            className="down"
                                                                                            onClick={(e) => decreaseTravels(e, 'adult')}
                                                                                        >
                                                                                            <i className="fa-solid fa-minus"></i>
                                                                                        </span>
                                                                                        <input type="text" readOnly
                                                                                            value={bookingFormValues.travellers.adult} />
                                                                                        <span
                                                                                            className="up"
                                                                                            onClick={(e) => increaseTravels(e, 'adult')}
                                                                                        >
                                                                                            <i className="fa-solid fa-plus"></i>
                                                                                        </span>
                                                                                    </div>
                                                                                </div>
                                                                            </div>

                                                                            <div className="row align-items-center">
                                                                                <div className="col-sm-6">
                                                                                    <h4>
                                                                                        Seniors <span>65+</span>
                                                                                    </h4>
                                                                                </div>
                                                                                <div className="col-sm-6 text-end">
                                                                                    <div className="quantity_counter">
                                                                                        <span
                                                                                            className="down"
                                                                                            onClick={(e) => decreaseTravels(e, 'seniors')}
                                                                                        >
                                                                                            <i className="fa-solid fa-minus"></i>
                                                                                        </span>
                                                                                        <input type="text" readOnly
                                                                                            value={bookingFormValues.travellers.seniors} />
                                                                                        <span
                                                                                            className="up"
                                                                                            onClick={(e) => increaseTravels(e, 'seniors')}
                                                                                        >
                                                                                            <i className="fa-solid fa-plus"></i>
                                                                                        </span>
                                                                                    </div>
                                                                                </div>
                                                                            </div>

                                                                            <div className="row align-items-center">
                                                                                <div className="col-sm-6">
                                                                                    <h4>
                                                                                        Child <span>2-11</span>
                                                                                    </h4>
                                                                                </div>
                                                                                <div className="col-sm-6 text-end">
                                                                                    <div className="quantity_counter">
                                                                                        <span
                                                                                            className="down"
                                                                                            onClick={(e) => decreaseTravels(e, 'child')}
                                                                                        >
                                                                                            <i className="fa-solid fa-minus"></i>
                                                                                        </span>
                                                                                        <input type="text" readOnly
                                                                                            value={bookingFormValues.travellers.child} />
                                                                                        <span
                                                                                            className="up"
                                                                                            onClick={(e) => increaseTravels(e, 'child')}
                                                                                        >
                                                                                            <i className="fa-solid fa-plus"></i>
                                                                                        </span>
                                                                                    </div>
                                                                                </div>
                                                                            </div>

                                                                            <div className="row align-items-center">
                                                                                <div className="col-sm-6">
                                                                                    <h4>
                                                                                        Infant In Seat <span>0-2</span>
                                                                                    </h4>
                                                                                </div>
                                                                                <div className="col-sm-6 text-end">
                                                                                    <div className="quantity_counter">
                                                                                        <span
                                                                                            className="down"
                                                                                            onClick={(e) => decreaseTravels(e, 'infant_in_seat')}
                                                                                        >
                                                                                            <i className="fa-solid fa-minus"></i>
                                                                                        </span>
                                                                                        <input type="text" readOnly
                                                                                            value={bookingFormValues.travellers.infant_in_seat} />
                                                                                        <span
                                                                                            className="up"
                                                                                            onClick={(e) => increaseTravels(e, 'infant_in_seat')}
                                                                                        >
                                                                                            <i className="fa-solid fa-plus"></i>
                                                                                        </span>
                                                                                    </div>
                                                                                </div>
                                                                            </div>

                                                                            <div className="row align-items-center">
                                                                                <div className="col-sm-6">
                                                                                    <h4>
                                                                                        Infant In Lap <span>0-2</span>
                                                                                    </h4>
                                                                                </div>
                                                                                <div className="col-sm-6 text-end">
                                                                                    <div className="quantity_counter">
                                                                                        <span
                                                                                            className="down"
                                                                                            onClick={(e) => decreaseTravels(e, 'infant_in_lap')}
                                                                                        >
                                                                                            <i className="fa-solid fa-minus"></i>
                                                                                        </span>
                                                                                        <input type="text" readOnly
                                                                                            value={bookingFormValues.travellers.infant_in_lap} />
                                                                                        <span
                                                                                            className="up"
                                                                                            onClick={(e) => increaseTravels(e, 'infant_in_lap')}
                                                                                        >
                                                                                            <i className="fa-solid fa-plus"></i>
                                                                                        </span>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            {bookingFormValues.totalTravellers > bookingFormValues.limitTravelers && (
                                                                                <div className="travellers-error-msg">
                                                                                    We are only able to book between 1
                                                                                    and 6 travelers.
                                                                                    <br />
                                                                                    If you need more travelers please
                                                                                    contact us:
                                                                                    <div
                                                                                        className={"travellers-error-msg__contacts"}>
                                                                                        <span>(212) 680-4905</span>
                                                                                        <span>info@flightinsiders.com</span>
                                                                                    </div>
                                                                                </div>
                                                                            )}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                        <div className="row">
                                            <div className="col-sm-12">
                                                <button
                                                    type="button"
                                                    className="btn mt-2"
                                                    data-bs-toggle="modal"
                                                    data-bs-target="#mobilePersonalInfoModal"
                                                    disabled={multiCityNextButtonDisabled}
                                                >
                                                    Get Quote
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </div>

                            {/* <div className="modal-footer">
                <button type="button" className="btn" data-bs-toggle="modal" data-bs-target="#mobilePersonalInfoModal">
                Get Quote
                </button>
              </div> */}
                        </div>
                    </div>
                </div>
            </div>
            {/* <!-- Mobile Personal Info Modal --> */}
            <div className="modal fade custom_modal3" id="mobilePersonalInfoModal" tabIndex="-1"
                aria-labelledby="mobilePersonalInfoModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-fullscreen">
                    <div className="modal-content">
                        <div className="modal-header">
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close">
                                <img src="images/back_arrow.png" alt="" />
                            </button>
                            <h2>Personal Info</h2>
                        </div>

                        <div className="modal-body">
                            {/* <div className="form-floating">
                <input
                    type="text"
                    className="form-control"
                    id="name"
                    placeholder=""
                    value={bookingFormValues.name}
                    onChange={(e) =>
                    handleBookingFormChange(e, {
                        name: e.target.value,
                    })
                    }
                    {...finalSubmission.getFieldProps("name")}
                />
                <div style={{ color: "red" }}>{renderErrorMessage("name")}</div>
                <label htmlFor="name">Name</label>
              </div> */}


                            <div className="form-floating">
                                <input
                                    type="text"
                                    className="form-control"
                                    id="first_name"
                                    name="first_name"
                                    placeholder="Name"
                                    required
                                    value={bookingFormValues.first_name}
                                    onChange={(e) =>
                                        handleBookingFormChange(e, {
                                            first_name: e.target.value,
                                        })
                                    }
                                />

                                <div
                                    style={{
                                        color: "red",
                                    }}
                                >
                                    {renderErrorMessage("first_name")}
                                </div>
                                <label htmlFor="name">First Name</label>
                            </div>

                            <div className="form-floating">
                                <input
                                    type="text"
                                    className="form-control"
                                    id="last_name"
                                    name="last_name"
                                    placeholder="Name"
                                    required
                                    value={bookingFormValues.last_name}
                                    onChange={(e) =>
                                        handleBookingFormChange(e, {
                                            last_name: e.target.value,
                                        })
                                    }
                                />

                                <div
                                    style={{
                                        color: "red",
                                    }}
                                >
                                    {renderErrorMessage("last_name")}
                                </div>
                                <label htmlFor="name">Last Name</label>
                            </div>


                            <div className="form-floating">
                                <input
                                    type="email"
                                    className="form-control"
                                    id="email"
                                    placeholder="Email"
                                    value={bookingFormValues.email}
                                    onChange={(e) =>
                                        handleBookingFormChange(e, {
                                            email: e.target.value,
                                        })
                                    }
                                // {...finalSubmission.getFieldProps("email")}
                                />
                                <div style={{ color: "red" }}>{renderErrorMessage("email")}</div>
                                <label htmlFor="email">Email</label>
                            </div>
                            <div className="form-floating">
                                <input
                                    type="text"
                                    className="form-control"
                                    id="phone"
                                    placeholder="Phone"
                                    value={bookingFormValues.phone}
                                    onKeyPress={(event) => {
                                        if (!/[0-9]/.test(event.key)) {
                                            event.preventDefault();
                                        }
                                    }}
                                    onChange={(e) =>
                                        handleBookingFormChange(e, {
                                            phone: e.target.value,
                                        })
                                    }
                                // {...finalSubmission.getFieldProps("phone")}
                                />
                                <div style={{ color: "red" }}>{renderErrorMessage("phone")}</div>
                                <label htmlFor="phone">Phone</label>
                            </div>
                            <div className="form-check">
                                <input
                                    className="form-check-input"
                                    type="checkbox"
                                    id="accommodation2"
                                    defaultChecked
                                    required
                                    value={finalSubmission.values.accommodation}
                                    onChange={finalSubmission.onChange}
                                />
                                <label className="form-check-label" htmlFor="accommodation2">
                                    Check accommodation with FlightInsiders.com
                                </label>
                            </div>
                        </div>
                        <div className="modal-footer">
                            {/* <button
                type="button"
                className="btn"
                data-bs-toggle="modal"
                data-bs-target="#mobileThankYouModal"
                disabled={!(finalSubmission.values.name && finalSubmission.values.phone && finalSubmission.values.email)}
                onClick={finalSubmission.handleSubmit}
                >
                Submit
              </button> */}
                            <button
                                type="submit"
                                className="btn"
                                data-bs-toggle="modal"
                                // data-bs-target="#mobileThankYouModal"
                                // disabled={!(finalSubmission.values.name && finalSubmission.values.phone && finalSubmission.values.email)}
                                disabled={submitButtonDisabled}
                                onClick={finalSubmission.handleSubmit}
                            >
                                Submit
                            </button>
                        </div>
                    </div>
                </div>
            </div>

            {/* <!-- Mobile Thank You Modal --> */}

            {/* {showSuccessModal === true && isMobile ? <ThankyouMobile setShowSuccessModal={setShowSuccessModal} showSuccessModal={showSuccessModal} /> : null} */}

            <div className="mobile_fixed_quote_btn fixed-bottom">
                <div className="row g-0">
                    <div className="col-sm-10">
                        <button type="button" className="btn" data-bs-toggle="modal"
                            data-bs-target="#mobileFlightBookingModal">
                            Get A Quote
                        </button>
                    </div>
                    <div className="col-sm-2">
                        <a className="btn wabtn"
                            target="_blank"
                            rel="noreferrer"
                            href={`https://api.whatsapp.com/send?phone=${siteSettings.whatsapp}`}
                        >
                            <img src="images/whatsapp_icon_2.svg" alt="" />
                        </a>
                    </div>
                </div>
            </div>
            <div className="mobile_banner_btn">
                <div className="follow_instagram">
                    <h6>Follow us on Instagram</h6>
                    <div className="social_icons">
                        <a target="_blank" rel="noreferrer"
                            href={socialMediaLinks?.site_settings?.instagram}>
                            <img src="images/instagram_icon.svg" alt="" />
                        </a>
                        {/* <a
                            target="_blank"
                            rel="noreferrer"
                            href={`https://api.whatsapp.com/send?phone=${formatPhoneNumber(socialMediaLinks?.site_settings?.whatsapp)}`}
                            >
                            <img src="images/whatsapp_icon.svg" alt="" />
                          </a> */}
                    </div>
                </div>
                <div className="how_it_works_section_web">
                    <img src="images/how_it_works_img.svg" alt="" />
                    <div className="row">
                        <div className="col-sm-4">
                            <div className="what_happens_box">
                                <i className="fa-solid fa-laptop"></i>
                                <div>
                                    <h5>Get in touch with our expert</h5>
                                    <p>Call us now at {Loading ? "" : (
                                        <a href={`tel:${socialMediaLinks?.site_settings?.phone}`}>{`${socialMediaLinks?.site_settings?.phone}`}</a>)} or
                                        submit your inquiry using the flight search tool
                                        on our website!</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-4">
                            <div className="what_happens_box">
                                <i className="fa-solid fa-user-tie-hair"></i>
                                <div>
                                    <h5>One of our Flight Insiders searches the best
                                        options for you</h5>
                                    <p>Every request is custom tailored by our
                                        knowledgeable travel experts to find you the
                                        best flights and price!</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-4">
                            <div className="what_happens_box">
                                <i className="fa-solid fa-plane-up"></i>
                                <div>
                                    <h5>Receive your options, book, and save</h5>
                                    <p>Get a competitive quote within minutes and book
                                        your trip today!</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default BookingForm;
